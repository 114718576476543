<!-- 工时管理 > 工时统计 > 项目工时统计(组件) > 项目工时明细(组件) -->
<template>
    <div class="fullScreen show">
        <Crumbs>
            <el-breadcrumb-item slot="after">
                <span class="close_btn" @click="handleDetailedClose">
                    工时明细<i class="el-icon-close"></i>
                </span>
            </el-breadcrumb-item>
        </Crumbs>
        <div class="mainList">
            <div class="mainList_content">
                <div class="mainList_content_sub">
                    <el-table
                            v-loading="loading"
                            style="width: 100%"
                            row-key="id"
                            height="string"
                            border
                            :data="tableData"
                            :header-cell-style="{ background: '#F4F4F4', textAlign: 'center' }"
                    >
                        <el-table-column prop="userName" label="填报人" align="center"></el-table-column>
                        <el-table-column prop="departmentName" label="所属部门" align="center"></el-table-column>
                        <el-table-column prop="projectName" label="项目名称" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column label="工时日期" align="center">
                            <template slot-scope="scope">
                                <span>{{ new Date(scope.row.manhourDate) | dateFormat }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="填报时间" align="center">
                            <template slot-scope="scope">
                                <span>{{ new Date(scope.row.submitDate) | dateFormat }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="审批时间" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.checkTime">{{ new Date(scope.row.checkTime) | dateFormat }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="workContent" label="工作内容" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="hour" label="工时(小时)" align="center"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            Crumbs: () => import("@/components/Crumbs.vue")
        },
        props: {
            isDetailedDlg: {
                type: Boolean,
                default: false
            },
            getInfoData: {
                type: Object,
                default: () => {
                    return {}
                }
            },
        },
        data() {
            return {
                loading: false,  // 表格 -- 加载 变量
                tableData: [],  // 表格 列表数据
            }
        },
        mounted() {
            this.getStatisticsDetailedListData();  /** 加载 "项目工时明细" 列表数据 **/
        },
        methods: {
            /** 加载 "项目工时明细" 列表数据 **/
            getStatisticsDetailedListData(){
                this.loading = true;
                let _param = {
                    projectId: this.getInfoData.projectId,
                    projectName: this.getInfoData.projectName,
                    userId: this.getInfoData.userId
                };

                this.$api.hour.hourStatisticsDetailed(_param).then(res => {
                    this.tableData = res.data;
                    this.loading = false;
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
            },

            /** 关闭 “项目工时明细” 组件 **/
            handleDetailedClose() {
                this.$emit("childEvent", false);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .close_btn{
        position:absolute;
        top: 10px;
        display: inline-block;
        color: #ffffff;
        background-color: #014D67;
        padding: 5px 12px 5px 16px;
        margin-left: 12px;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .close_btn:hover{
        cursor: pointer;
    }

    .search_box{
        width: 100%;
        padding: 10px 16px;
        box-sizing: border-box;

        .search_div{
            position: relative;
            background-color: #ffffff;
            padding: 11px 20px;
            box-sizing: border-box;
            box-shadow: 2px 2px 4px #D1D1D1;

            .search_ipt{
                margin-bottom: 11px;
                label{
                    margin-right: 20px;

                    span{
                        margin-right: 10px;
                    }
                }
            }

            .close{
                position: absolute;
                top: 7px;
                right: 20px;
                padding: 8px 8px;
                box-sizing: border-box;
            }
        }
    }

    .table_box{
        width: 100%;
        background-color: #ffffff;
        padding: 10px 16px;
        box-sizing: border-box;

        .pagination{
            text-align: right;
            margin-top: 10px;
        }
    }

    .btn{
        color: #ffffff;
        background-color: #014D67;
        border: 1px solid #014D67;
    }
</style>
